import Swiper from '@iris.interactive/handcook/public/scripts/libraries/swiper-11'

import Rellax from 'rellax/rellax'

export default class BlockCustomerStrateTempsForts {
    constructor() {
        if ($('[data-element="block-strate-basic-slider"]').length > 0) {
            BlockCustomerStrateTempsForts.buildSwiper1()
            BlockCustomerStrateTempsForts.buildSwiper2()
            BlockCustomerStrateTempsForts.parallaxHorizontal()
        }
    }

    static async buildSwiper1() {
        const element = $('[data-element="block-strate-temps-forts"]')
        if (
            element.length > 0 &&
            !element.is(':hidden') &&
            !$('body').hasClass('eco-mode-active')
        ) {
            const swiperKey = 'temps-forts-cards1'
            const slideItem = `[data-swiper="${swiperKey}"] > .block__customer__strate-temps-forts__card`

            const options = {
                slidesPerView: 1,
                spaceBetween: 40,
                speed: 1000,
                keyboard: true,
                loop: true,
                freeMode: {
                    enabled: false,
                },
                freeModeFluid: false,
                allowTouchMove: false,
                passiveListeners: false,
                watchSlidesVisibility: false,
            }

            $(slideItem).wrapAll('<div class="swiper-wrapper"></div>').addClass('swiper-slide')

            const mySwiper1 = await Swiper.create(`[data-swiper="${swiperKey}"]`, options)

            $('.swiper-strate-temps-forts-swiper-prev').on('click', () => {
                mySwiper1.slidePrev()
            })
            $('.swiper-strate-temps-forts-swiper-next').on('click', () => {
                mySwiper1.slideNext()
            })
        }
    }

    static async buildSwiper2() {
        const element = $('[data-element="block-strate-temps-forts"]')
        if (
            element.length > 0 &&
            !element.is(':hidden') &&
            !$('body').hasClass('eco-mode-active')
        ) {
            const swiperKey = 'temps-forts-cards2'
            const slideItem = `[data-swiper="${swiperKey}"] > .block__customer__strate-temps-forts__card`

            const options = {
                slidesPerView: 2,
                spaceBetween: 10,
                speed: 1000,
                loop: false,
                initialSlide: 1,
                freeMode: {
                    enabled: false,
                },
                freeModeFluid: false,
                allowTouchMove: true,
                passiveListeners: false,
                watchSlidesVisibility: false,
                breakpoints: {
                    600: {
                        slidesPerView: 2,
                        spaceBetween: 20,
                        loop: false,
                        allowTouchMove: true,
                    },
                    1000: {
                        slidesPerView: 1.001,
                        spaceBetween: 40,
                        loop: true,
                        allowTouchMove: false,
                    },
                },
            }

            $(slideItem).wrapAll('<div class="swiper-wrapper"></div>').addClass('swiper-slide')

            const mySwiper2 = await Swiper.create(`[data-swiper="${swiperKey}"]`, options)

            $('.swiper-strate-temps-forts-swiper-prev').on('click', () => {
                mySwiper2.slidePrev()
            })
            $('.swiper-strate-temps-forts-swiper-next').on('click', () => {
                mySwiper2.slideNext()
            })
        }
    }

    static parallaxHorizontal() {
        const element = $('.block__customer__strate-temps-forts .strate-moutain')
        if (
            element.length > 0 &&
            !element.is(':hidden') &&
            !$('body').hasClass('eco-mode-active') &&
            window.matchMedia('(min-width: 1000px)').matches
        ) {
            const outerH = element.outerHeight()
            let overflowLeft = outerH / 3
            if ($(window).width() < 1200) {
                overflowLeft = outerH / 2
            }
            const overflowWidth = element.width() + overflowLeft
            element.css('left', -overflowLeft).css('width', overflowWidth)
            const rellax = new Rellax('.strate-moutain', { horizontal: true, center: true })
        }
    }
}

new BlockCustomerStrateTempsForts()
